const colors = {
  brand: {
    primary: '#2B2AEC',
    secondary: '#FF5F19',
    white: '#FFFFFF',
    white50: 'rgba(255, 255, 255, 0.50)',
  },
  text: {
    primary: '#111827',
    secondary: '#4B5563',
    disabled: '#9CA3AF',
    gray: '#666668',
    link: '#1400DC',
    linkVisited: '#6A4FF6',
    lightGrey: '#7f8285',
    tertiary: '#9CA3AF',
    brandLight: '#E9EAFD',
    success: '#00AC5F',
    brand: '#2B2AEC',
  },
  icon: {
    primary: '#4B5563',
    secondary: '#AFAFAF',
    active: '#1400DC',
    disabled: '#9CA3AF',
    bgOtherCompanyIcon: '#F4F7FA',
    fileBlue: '#75ADEA',
    fileGreen: '#69C57F',
    fileRed: '#FB6A6A',
    fileOrange: '#ED9254',
    gray: '#8E8E93',
    brand: '#2B2AEC',
    redLite: '#f25c64',
    primaryInverse: '#FFFFFF',
    error: '#eb2a38',
  },
  accent: {
    success: '#5FCE8C',
    successBackground: '#E9FAEF',
    error: '#F25C64',
    errorBackground: '#FFEBEF',
    warning: '#F6A85C',
    warningBackground: '#FFF3DC',
    active: '#3081FA',
    activeBackground: '#EBF5FF',
    cancel: '#AFAFAF',
    cancelBackground: '#F3F3F5',
    selected: '#0042690d',
  },
  buttons: {
    active: '#1400DC',
    hover: '#2B2AEC',
  },
  switch: {
    checked: '#2F80ED',
  },
  background: {
    primary: '#FCFCFC',
    secondary: '#F5F5F5',
    secondaryHover: '#EBEBEB',
    secondaryActive: '#DDDDDD',
    cargoDetails: '#f4f6fb',
    success: '#24C38E',
    ghost: '#00203314',
    alert: '#FF4443',
    heavy: '#E5E7EB',
    brand: '#2b2aec',
    brandHeavy: '#C8CAFA',
    tertiary: '#F3F4F6',
    successHovered: '#5FCE8C',
  },
  divider: {
    primary: '#EFEFEF',
    secondary: '#D1D5DB',
  },
  border: {
    disabled: '#F9F9F9',
    active: '#EBEBEB',
    hover: '#DADBDD',
    focus: '#B9B9BB',
    primary: '#EFEFEF',
    gray: ' #e5e5e5',
  },
  opacity: {
    background: 0.1,
  },
  local: {
    white10: '#ffffff1a',
    icon: '#727CC0',
    active: '#3081FA',
    active3: '#5066FF08',
    active5: '#0057ff12',
    active40: '#5066ff66',
    bgLanding: '#F3FAFF',
    black: '#212121',
    black10: '#2121211A',
    black20: '#00000033',
    black50: '#00000080',
    black60: '#00000099',
    black90: '#212121e5',
    grayDark: '#3F4A56',
    gray30: '#d1d6db',
    gray: '#667587',
    disabled: '#A2C5FA',
  },
  db: {
    widgetBg: '#2D3244',
  },
  tr: {
    success: '#24c38e',
    hover: '#e3eaf5',
  },
  message: {
    inProcessing: '#3080FA33',
    executed: '#FFF3DC',
    clarify: '#727CC033',
    calc: '#00ac5f33',
    approve: '#727CC033',
    cancelPerformer: '#FFEBEF',
    cancelCustomer: '#FFEBEF',
    sendMessage: '#9797971A',
    text: '#383838',
    date: '#787878',
  },
  info: { contrast: '#FFFFFF' },
  typo: { alert: '#EB3333', ghost: '#0020334d', controlClear: '#00395CCC', primary: '#002033' },
};

export default colors;
