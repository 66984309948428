import { makeAutoObservable, runInAction } from 'mobx';
import api from 'shared/api/api';
import auth from 'shared/api/auth';
import { StateType, UserRole, UserType } from './model';

class UserStore {
  /**
   * Статус загрузки текущего юзера с сервера.
   */
  public state: StateType = 'Pending';

  /**
   * Данные о текущем пользователе.
   */
  public user: UserType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Загрузка данных о текущем пользователе.
   */
  public async loadUser() {
    this.state = 'Pending';
    this.user = null;
    const token = await auth.getToken();

    try {
      const { result } = await api.get<UserRole>(`v2/identity/client-role`, false, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (result !== 'Anonymous') {
        const { result: clientInfo } = await api.get<UserType>(`v2/identity/client-info`, false, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        runInAction(() => {
          this.user = { result: { ...clientInfo, role: result } };
          this.state = 'Done';
        });
      } else {
        runInAction(() => {
          this.user = { result: { role: result } };
          this.state = 'Done';
        });
      }
    } catch {
      runInAction(() => {
        this.state = 'Error';
      });
    }
  }
}

export default new UserStore();
