import { TransportType, CarType, CargoType, AirType, ServiceType } from 'entities';
import { StatusOrder } from 'entities/order/model';
import { TrainType, TransportTypeFilter } from 'entities/transport/model';
import { UserRole } from 'entities/user';

const ru = {
  Common: {
    Error: 'Ошибка',
  },
  Features: {
    Startup: {
      AppLoadingText: 'Загрузка тарификатора...',
    },
    Footer: {
      CargoConnect: 'CargoConnect',
    },
    Header: {
      Main: 'Главная',
      CargoConnect: 'CargoConnect',
      CalculationAndOrder: 'Расчет и заказ',
      Orders: 'Заказы',
      Сontacts: 'Контакты',
      Login: 'Войти',
      Logout: 'Выход',
    },
    NotificationCenter: {
      NoNewNotificatians: 'Нет новых уведомлений',
      Notifications: 'Уведомления',
      MarkEverythingAsRead: 'Отметить все прочитанным',
      ClearAllNotifications: 'Очистить все уведомления',
      EnableNotifications: 'Включить уведомления',
      DisableNotifications: 'Выключить уведомления',
      GoOrder: 'Перейти',
      Error: 'Произошла ошибка',
      HiddenAll: 'Скрыть все',
      Today: 'Сегодня',
      Yesterday: 'Вчера',
      More: 'Подробнее',
      RollUp: 'Свернуть',
      Copy: 'Скопировать',
    },
    Orders: {
      TableHeadTitle1: 'Чекбокс',
      TableHeadTitle2: 'Заказ',
      TableHeadTitle3: 'Статус',
      TableHeadTitle4: 'Cоздан',
      TableHeadTitle5: 'Загрузка',
      TableHeadTitle6: 'Откуда',
      TableHeadTitle7: 'Куда',
      TableHeadTitle8: 'Груз',
      TableHeadTitle9: 'Места',
      TableHeadTitle10: 'Габариты, м',
      TableHeadTitle11: 'Вес, кг',
      TableHeadTitle12: 'Вариант доставки',
    },
    Home: {
      Main: {
        TitlePart1: 'Промышленные',
        TitlePart2: 'перевозки по России',
        SubtitlePart1: 'Сервис грузоперевозок ',
        SubtitlePart2: 'любой сложности',
        SubmitButton: 'Рассчитать',
        TitleCalc: 'Рассчитать стоимость',
      },
      OurNumberSection: {
        Items: [
          {
            Title: '20 лет',
            Description: 'на рынке грузоперевозок',
          },
          {
            Title: '1500',
            Description: 'рейсов ежемесячно',
          },
          {
            Title: '17 тыс.тонн',
            Description: 'ежемесячный объем грузоперевозок',
          },
          {
            Title: '1000+',
            Description: 'единиц транспорта в управлении',
          },
        ],
      },
      DeliverySection: {
        Title: 'Доставим ваш груз по земле и воздуху',
        Items: [
          {
            Title: 'Авто перевозка',
            Description: 'Для грузов любых габаритов от 1 кг до 100 тонн',
          },
          {
            Title: 'Ж/Д перевозка',
            Description: 'Для перевозки больших объемов, когда есть время',
          },
          {
            Title: 'Авиа перевозка',
            Description: 'Для доставки в трудноступные регионы',
          },
          {
            Title: 'Мульти&shy;модальная',
            Description: 'Для перевозок, требующих комплексный подход',
          },
        ],
      },
      IndustrySolutions: {
        Title: 'Отраслевые решения',
        Items: [
          {
            Title: 'Нефтегаз - доставка в труднодоступные регионы',
            DescriptionPart1: `Аккредитованные водители имеют постоянные пропуска на объекты нефтедобычи, а автопарк - всё специализированное оборудование.`,
            DescriptionPart2: `Когда не работают зимники, осуществляем доставку воздухом на вертолетах ми-8 и ми-26 или беспилотными летательными аппаратами.`,
          },
          {
            Title: 'Химическая промышенность',
            DescriptionPart1: `Лидеры в своей отрасли доверяют нам доставку хим. продуктов по территории России: удобрения, реагенты, кислоты, горючие материалы.
            У нас имеются все необходимые разрешения на перевозку грузов 3, 4, 5, 8 и 9 классов опасности.`,
          },
          {
            Title: 'Строительство, металлургия и машиностроение',
            DescriptionPart1: `Наша команда осуществляет перевозки негабаритных грузов любого уровня сложности: строительная и буровая техника, трубная продукция и многое другое.
              В нашем управлении имеются тралы и низкорамные площадки грузоподъемностью до 100 тонн.`,
          },
        ],
      },
      ServicesSection: {
        Title: 'Полный спектр услуг',
        Items: [
          {
            Title: 'Стра&shy;хо&shy;ва&shy;ние',
            Description: 'Страхуем грузы стоимостью до 500 млн руб',
          },
          {
            Title: 'Срочная доставка',
            Description: 'Подадим транспорт в течение 24 часов с доп. водителем для скорейшей доставки',
          },
          {
            Title: 'Ре&shy;фри&shy;же&shy;ра&shy;тор',
            Description: 'Выдержим требуемый температурный режим',
          },
          {
            Title: 'Опасный груз',
            Description: 'Перевозим грузы 3, 4, 5, 8 и 9 классов опасности',
          },
        ],
      },
      TheyTrustUs: {
        Title: 'Нам доверяют',
        OtherCompany1: '+75',
        OtherCompany2: 'компаний',
      },
      DeliveryStepsSection: {
        Title: 'Три шага до перевозки и оплата по факту',
        Steps: [
          {
            Title: 'Расчет',
            Description: 'Рассчитайте стоимость в ',
            link: 'калькуляторе',
          },
          {
            Title: 'Заявка',
            Description: 'Оставьте заявку, и мы свяжемся с Вами',
          },
          {
            Title: 'Договор',
            Description: 'Заключаем договор на перевозку',
          },
          {
            Title: 'Готово',
            Description: 'Мы перевозим груз, Вы оплачиваете по факту доставки',
          },
        ],
      },
      ContactsSection: {
        NotAcceptedDialogTextPart1: 'Ваше обращение не было принято.',
        NotAcceptedDialogTextPart2: 'Повторите позже или обратитесь к администратору.',
        AcceptedDialogTextPart1: `Ваше сообщение отправлено`,
        AcceptedDialogTextPart2: `В ближайшее время оператор свяжется с Вами.`,
        SubmitingErrorPart1: 'Во время отправления заявки произошла ошибка.',
        SubmitingErrorPart2: 'Повторите позже или обратитесь к администратору.',
        Title1: 'Остались вопросы?',
        Title2: 'Свяжитесь с нами!',
        Email: 'Email',
        Comment: 'Комментарий',
        SubmitButton: 'Отправить',
        UserAgreementText: 'Оставляя заявку, вы соглашаетесь с',
        UserAgreementLink: ' условиями пользовательского соглашения',
        CloseModal: 'Закрыть',
      },
    },
    OrdersPage: {
      GuiError: 'Ошибка при загрузке заказов',
      GuiErorTable: 'Ошибка в таблице заказов',
      GuiErrorFilters: 'Ошибка при отображении фильтров',
      OrdersEmpty: 'Нет данных для отображения',
      ResetFilterEmptyOrder: 'Сбросить фильтр',
      OrdersNumber: 'Заказы №',
      Join: 'Объединить',
      Separate: 'Отделить',
      Notification: (orderNumber: number, status: string) => {
        return `Заказ ${orderNumber}  статус изменен на “${status}”`;
      },
      Filters: {
        Search: 'Поиск',
        Sort: 'Сортировка',
        Delivery: 'Доставка',
        Source: 'Источник',
        Transport: 'Транспорт',
        Status: 'Статус',
        OrderNumber: 'Номер заявки',
        OrderTime: 'Дата создания',
        StatusTime: 'Дата изменения статуса',
        ShipmentTime: 'Дата отгрузки',
        Reset: 'Сбросить',
        Allocated: 'Отдельный',
        Groupage: 'Сборный',
        MyOrders: 'Мои заказы',
      },
      OrdersModalHandler: {
        Responsible: 'Ответственный:',
        NonResponsible: 'Не назначен',
        NonFinalCost: 'Не финальная стоимость',
        FinalCost: 'Финальная стоимость',
        Cargo: 'Груз',
        History: 'История',
        Files: 'Файлы',
        Source: 'Источник:',
        MaxDimensions: 'Максимальные габариты:',
        CargoTypes: 'Виды груза (кол-во):',
        PacksCount: 'Грузомест (кол-во):',
        DeclaredValue: 'Объявленная стоимость:',
        AdditionalServices: 'Дополнительные услуги / требования',
        Comment: 'Комментарий',
        TypeCargo: 'Тип груза:',
        WeightCargo: 'Вес:',
        QuantityCargo: 'Количество:',
        Cost: 'Стоимость:',
        CostInput: 'Стоимость',
        Dimensions: 'Размеры:',
        OrdersNumber: 'Заказ №',
        Pickup: 'Отгрузка',
        RequestPickup: 'Запрошенная отгрузка:',
        DropoffCostCard: 'Доставка',
        Dropoff: 'Желаемая доставка',
        Confirm: 'Подтвердить',
        Edit: 'Изменить',
        Save: 'Сохранить',
        TooltipPickup: 'Ориентировочная \nдата прибытия \nв пункт отгрузки',
        TooltipDropoff: 'Дата доставки \nдо грузополучателя',
        InfoNonFinalCost: `Актуализируйте стоимость, тип транспорта
        и даты отгрузки/доставки, если необходимо добавьте варианты доставки,
        отправьте на согласование клиенту.`,
        InfoFinalCost: 'Отправьте заявку на согласование клиенту, если есть вопросы нажмите “на уточнение”',
        InfoClarify: 'Заявка на уточнении у клиента',
        InfoFindExecutor: 'Добавьте исполнителя, загрузите документы если необходимо напишите комментарий',
        InfoReservClarify: 'Заказ находится на уточнении в Reserve',
        InfoApproval: 'Заявка на согласовании у клиента',
        InfoToWork: 'Оправьте заявку “в работу”, если есть вопросы нажмите “на уточнение”',
        InfoCancelCustomer: (date: string) => `Отменен заказчиком (${date})`,
        InfoCancelLogist: (date: string) => `Отменен исполнителем (${date})`,
        InfoDivisoinsApproval: 'Согласуйте финальную стоимость и даты отгрузки/доставки',
        InfoDivisoinsInProcess: 'Ожидайте подтверждения Цифровой логистики о взятии заказа в работу',
        InfoDivisoinsClarify: 'Менеджер запросил уточнение по заказу, подробнее по вкладке “история”',
        HistoryInput: 'Введите комментарий...',
        Export: 'Экспорт',
        TransportType: 'Тип транспорта',
        Quantity: 'Кол-во',
        AddWagon: 'Добавить вагон',
        AddTransport: 'Добавить транспорт',
        AddVariant: 'Добавить вариант',
        NoVATCost: 'Стоимость без НДС:',
        Profitability: 'Рентабельность:',
        ChooseTypeTransport: 'Выберите тип',
        CourierDelivery: 'Курьерская доставка',
        DeliveryOptions: 'Варианты доставки',
      },
      OrdersDetails: {
        Order: 'Заказ',
        Orders: 'Заказы',
        ResponsibleDivision: 'Ответственный (дивизион)',
        PhoneExt: 'Доб.',
        Contacts: 'Контакты',
        Sender: 'Отправитель',
        Recipient: 'Получатель',
        SizesMax: 'Размеры Max:',
        WeightVolume: 'Вес/объем:',
        CostCargo: 'Cтоимость груза:',
        CostOrder: 'Cтоимость без НДС:',
      },
      OrderCargos: {
        Cargo: 'Грузы',
        TotalWeight: 'Общая масса:',
        MaxDimensions: 'Максимальные габариты:',
        CostTotalCargo: 'Общая стоимость груза:',
        FromWhereToWhere: 'Откуда/Куда:',
        Table: {
          TableHeadTitle1: 'Заказ',
          TableHeadTitle2: 'Позиция',
          TableHeadTitle3: 'Габариты',
          TableHeadTitle4: 'Общий вес',
          TableHeadTitle5: 'Кол-во, шт.',
        },
      },
      OrderChat: {
        ChatOnOrder: 'Чат по заказу',
      },
      OrderNavigation: {
        Assigned: 'Назначен:',
      },
      OrderCalculation: {
        Calculation: 'Расчеты',
        Details: 'Детали',
      },
      ModalAppointment: {
        Confirm: 'Подтверждение',
        Description: 'Напишите зачем вы меняете менеджера',
        Comment: 'Комментарий',
        Cancel: 'Отменить',
        Apply: 'Применить',
      },
      FooterPanel: {
        ForApproval: 'На согласование',
        Approval: 'Согласовать',
        ForClarify: 'На уточнение',
        CancelOrder: 'Отмена заказа',
        ToWork: 'В работу',
        Edit: 'Изменить',
        Cancel: 'Отменить заказ',
        Base: 'Доставка до базы:',
        AjustOrder: 'Сохранить уточнения',
        AddPerformer: 'Добавить исполнителя',
        SeparatureAllOrders: 'Разбить весь заказ',
        SeparateOrder: 'Отделить заказ',
      },
      Modal: {
        DescApproval: 'Оправить заявку на согласование клиенту?',
        Cancel: 'Отменить',
        CancelOrder: 'Отменить заказ',
        Send: 'Отправить',
        DescClarify: 'Напишите что хотите уточнить у клиента.',
        Comment: 'Комментарий',
        Close: 'Закрыть',
        ReasonCancel: 'Укажите причину отмены.',
        Files: 'Файлы',
        AddPerformer: 'Добавление исполнителя',
        UploadFilePerformer: 'Загрузите документы на исполнителя, если необходимо напишите комментарий.',
      },
      Message: {
        Status: 'Статус:',
        OrderChanged: 'Заказ был изменен',
        CostChanged: 'Cтоимость была изменена:',
        PickupDateChanged: 'Изменена дата отгрузки:',
        DropoffDateChanged: 'Изменена дата доставки:',
      },
    },
    Calc: {
      GuiError: 'Ошибка при обработке заказа.',
      ServerError:
        'Автоматический расчет невозможен для данных параметров. \nДля получения стоимости отправьте заявку оператору.',
      Order: {
        TitleFirstStage: 'Расчет и оформление заказа',
        TitleSecondStageOrder: 'Оформление заказа',
        TitleSecondStageApplication: 'Оформление заявки',
        TitleHint: 'Кликните, чтобы начать оформление заказа заново.',
        TitleInsurance: 'Укажите данные для страхования',
        TitleUploader: 'Загрузите необходимые документы/схемы/фотографии',
        TitleEditOrder: 'Редактирование заказа',
        SubtitleQuickOrder:
          'Для быстрого заказа достаточно ваших контактных данных, все остальные параметры обсуждаются с менеджером по телефону.',
        ClearForm: 'Очистить форму',
        QuickOrder: 'Быстрый заказ',
        DedicatedTransport: {
          Title: 'Выделенный транспорт',
          TransportType: 'Тип транспорта',
          MoreOptions: 'Ещё варианты',
          GuiError: 'Ошибка при загрузке выделенного транспорта',
          AirFlightRange: 'Расстояние перевозки ограничено радиусом полета 600-800 км в зависимости от транспорта',
        },
        Railways: {
          Title: 'Повагонная отправка',
          WagonDispatchWarning: 'Осуществляем только повагонную отправку!',
        },
        Details: {
          GuiError: 'Ошибка при обработке деталей заказа.',
          PickupLocation: 'Откуда',
          DropoffLocation: 'Куда',
          PickupDate: 'Дата/период погрузки',
          DropoffDate: 'Дата/период выгрузки',
          RouteLength: 'Расстояние ati.su',
          AtPlaceOfShipment: 'По месту отгрузки',
          AtPlaceOfDelivery: 'По месту доставки',
        },
        TransportTypes: {
          Title: 'Вид перевозки',
        },
        Cargo: {
          GuiError: 'Ошибка при обработке грузов.',
          Title: 'Информация о грузе',
          Shipping: (count: number) => `Плечо перевозки ${count}`,
          Count: (count: number) => `Грузомест: ${count}`,
          CountWagon: (count: number) => `Вагоны: ${count}`,
          Add: 'Добавить позицию',
          AddShipment: 'Добавить перевозку',
          AddDescription: 'Добавить описание груза',
          AddDescriptionMobile: 'Добавить описание',
          DefaultName: 'Новый груз',
          DeletePack: 'Удалить позицию',
          Pack: {
            ETSNGLabel: 'Груз по ЕТСНГ',
            ETSNGPlaceholder: 'Введите код ЕТСНГ или наименование',
            TariffClass: 'Тарифный класс:',
            GuiError: 'Ошибка при обработке упаковки.',
            Description: 'Описание',
            DescriptionPlaceholder: 'Введите информацию о грузе',
            noPropertiesTypes: 'Не знаю параметры груза',
            Type: 'Тип груза',
            Dimensions: 'Габариты (ДxШxВ)',
            Weight: 'Вес единицы, кг',
            Volume: 'Объём, м3',
            TotalWeight: 'Общая масса, кг',
            Quantity: 'Кол-во, шт',
            Price: 'Стоимость груза, ₽',
          },
        },
        Dates: {
          GuiError: 'Ошибка при обработке дат отгрузки/доставки.',
          DateIntervalsHeader: 'Желаемые даты погрузки/выгрузки',
        },
        Options: {
          GuiError: 'Ошибка при обработке вариантов доставки.',
          CalculationError: 'Не удалось рассчитать варианты доставки.',
          Title: 'Варианты доставки',
          MoreOptions: 'Еще варианты',
          ServicesTitle: 'Дополнительные услуги',
          ManualCalculation: 'Расчет производится оператором после отправки заявки',
          Pickup: 'Отгрузка',
          FlightTime: 'Полётное время',
          Dropoff: 'Доставка',
          VehicleType: 'Тип а/м:',
          AviaType: 'Тип авиа:',
          DefaultType: 'Тип:',
          VechicleWeightCapacity: (capacity: string) => `Тип а/м: ${capacity}`,
          AviaWeightCapacity: (capacity: string) => `Тип авиа: ${capacity}`,
          DefaultWeightCapacity: (capacity: string) => `Тип: ${capacity}`,
          RouteLength: (length: number) => `Расстояние: ${length} км.`,
          Tariff: (tariff: number) => `Тариф: ${tariff} (руб./км.)`,
        },
        Services: {
          Placeholders: {
            Insurance: 'Укажите стоимость груза',
          },
          Description: {
            Insurance: 'Введите стоимость груза для расчета страхового взноса',
            InsuranceStart: 'Страховой взнос',
            InsuranceEnd: '₽',
          },
          ErrorDescriptions: {
            Insurance: 'Для расчета страховки необходимо указать стоимость груза',
          },
        },
        Contacts: {
          GuiError: 'Ошибка при обработке контактных данных.',
          Title: 'Контактные данные',
          TitleCustomer: 'Заказчик',
          TitlePayer: 'Укажите данные плательщика',
          TitleCustomerTooltip: `С кем мы можем связаться для уточнения деталей?`,
          Sender: 'Отправитель',
          Recipient: 'Получатель',
          OtherPerson: 'Третье лицо',
          Divisions: 'Дивизион',
          Pickup: 'Погрузка',
          Dropoff: 'Разгрузка',
          Payer: 'Плательщик',
          PayerMVZ: 'МВЗ плательщика',
          Address: 'Адрес',
          Email: 'Email',
          Phone: 'Телефон',
          PhoneExtension: 'Доб. номер',
          PhoneExt: 'Доб.',
          Person: 'Контактное лицо',
          AddCommentPickup: 'Добавить комментарий к погрузке',
          AddCommentDroppoff: 'Добавить комментарий к разгрузке',
          AddComment: 'Добавить комментарий',
          Responsible: 'Ответственный',
        },
        UploaderFile: {
          Title: 'Список загруженных файлов:',
          ErrorLength: 'Слишком длинное название файла',
          ErrorWeigth: 'Превышен максимальный размер файлов. Все файлы не более 5 Мб.',
          ErrorExt: 'Файлы неподдерживаемых форматов не были загружены',
          ErrorQuantity: 'Можно прикрепить не более 3 файлов. Файлы свыше 3 не были загружены',
          Upload: 'Загрузить файл',
          DescriptionPart1: (formatFiles = '') => {
            return `Поддерживаемые форматы: ${formatFiles}.`;
          },
          DescriptionPart2: (quantityFiles = 0) => {
            return `Не более ${quantityFiles} файлов.`;
          },
          DescriptionPart3: (totalWeight = '0') => {
            return `Все файлы не более ${totalWeight} Мб.`;
          },
          DescriptionPart4: (nameLength = 0) => {
            return `Имя файла должно быть короче ${nameLength} символов и не должно
            содержать знаки \\ и /`;
          },
        },
        Description: {
          GuiError: 'Ошибка при обработке комментария.',
          Title: 'Комментарий к перевозке',
          Placeholder:
            'Опишите дополнительные требования, пожелания, информацию. (например: нужны обрезиненные коники)',
        },
      },
      Summary: {
        GuiError: 'Ошибка при обработке расчета.',
        Title: 'Ваш расчет',
        WeightWarningPart1: 'Максимальный вес груза ',
        WeightWarningPart2: '100 т.',
        WeightWarningAirPart2: '20 т.',
        Loading: 'Ожидайте, рассчитываем стоимость...',
        RequiredFieldsWarning: 'Для автоматического расчёта заполните обязательные поля.',
        ChooseTypeTransport: 'Выберите тип транспорта!',
        Profitability: 'Рентабельность',
        TransportationCargo: 'Перевозка',
        OrderDetails: {
          DeliveryInfo: 'Доставка:',
          PacksCount: 'Грузомест:',
          CargoInfo: 'Общий вес / объем:',
          CargoWeight: 'Общий вес:',
          RouteLength: 'Расстояние:',
          VehicleType: 'Тип а/м:',
          OwnWagonsPrice: 'Тариф СПС:',
          GeneralWagonsPrice: 'Тариф МПС:',
          Tariff: 'Тарифный коэффициент:',
          RentalPrice: 'Цена аренды:',
          WeightPerWagon: 'Вес в одном вагоне:',
          WagonLoadCapacity: 'Грузоподъёмность:',
          WagonCounts: 'Количество вагонов:',
        },
        SelectedServices: {
          Title: 'Услуги',
        },
        TotalCost: {
          Departure: 'Отгрузка',
          Delivery: 'Доставка',
          Cost: 'Стоимость с НДС',
          NoVATCost: 'Стоимость без НДС',
          NoVAT: 'Без НДС',
          VAT: 'НДС',
        },
        Submit: {
          ButtonTitleSecondStageOrder: 'Оформить заказ',
          ButtonTitleSecondStageApplication: 'Отправить заявку',
          ButtonTitleFirstStage: 'Перейти к оформлению',
          SaveChanges: 'Применить',
          Cancel: 'Отменить',
          CostDisclamer: {
            DisclamerStart: '* Указана ',
            DisclamerHover: 'ориентировочная',
            DisclamerEnd: ' стоимость на дату отправки',
            DisclamerTooltip: 'Будет уточнена оператором',
          },
        },
      },
    },
    AboutCompany: {
      Title: 'О компании',
      DescriptionLabel: 'CargoConnect',
      DescriptionPart1: ` – комплексный оператор услуг в области промышленной логистики с 20-летним опытом.`,
      DescriptionPart2: `Мы обеспечением предприятия нефтегазовой, машиностроительной, химической, металлургической, строительной и других отраслей. Специализируемся на сложных поставках негабаритного оборудования, работе в труднодоступных регионах.`,
      DescriptionPart3: `В нашем управлении, помимо наземного транспорта, находятся морские суда и воздушный транспорт.`,
    },
    Map: {
      Title: 'На карте',
      Coordinates: 'Координаты:',
      Cancel: 'Отменить',
      Select: 'Выбрать',
      UnknownLocation: 'Неизвестная локация',
      Region: 'область',
      ReplaceRegion: 'обл',
    },
    SubmitResult: {
      Success: {
        Title: 'Заявка отправлена',
        SuccessMessage: 'В ближайшее время оператор свяжется \nс Вами для подтверждения заявки.',
        EmailMessage: 'Копия заявки отправлена на ',
        NewCalc: 'Новый расчёт',
      },
      Error: {
        Title: 'Ошибка отправки',
        Error: 'Ваш заказ не был принят.',
        ErrorMessage: 'Повторите позже или обратитесь в ',
        HelpLink: 'поддержку',
        BackToCalc: 'Вернутся к расчету',
      },
    },
  },
  Types: {
    TransportTypeFilter: ((): Record<TransportTypeFilter, string> => ({
      VehicleNonAllocated: 'Сборный а/м',
      VehicleAllocated: 'Выделенный а/м',
      Train: 'Ж/Д',
      Air: 'Авиа',
    }))(),
    TransportTypeShort: ((): Record<TransportType, string> => ({
      Vehicle: 'А/М',
      Train: 'Ж/Д',
      Air: 'Авиа',
    }))(),
    TransportType: ((): Record<TransportType, string> => ({
      Vehicle: 'А/М перевозка',
      Train: 'Ж/Д перевозка',
      Air: 'Авиа перевозка',
    }))(),
    PackTypes: ['Бухта', 'Палетта FIN', 'Связка', 'Трубы', 'Оборудование'],
    CargoTypes: ((): Record<Exclude<CargoType, 'None'>, string> => ({
      Type1: 'Небольшой и воздушный', // Важен только объем, по габаритам единицы пролезает куда угодно
      Type2: 'Плотный и небольшой', // Важна только масса
      Type3: 'Длинный_1 (до 6 метров)', // По габаритам не подходят 3 первых типа, важна масса
      Type4: 'Длинный_2 (до 13,6 метров)', // По габаритам подходят машины начиная с 20т, важна масса
      Type5: 'Очень длинный (до 16м)', // По габаритам подходят машины начиная с 22т, важна масса
    }))(),
    CarType: ((): Record<CarType | 'CourierDelivery', string> => ({
      Type_1_5t: '1,5 т/8м³',
      Type_3t: '3 т/14м³',
      Type_5t: '5 т/20м³',
      Type_10t: '10 т/32м³',
      Type_20t: '20 т/82м³',
      Type_20tplus: '20+ т/113м³',
      Type_22t: '22 т/120м³',
      Type_30t: '30 т/120м³',
      Type_35t: '35 т/180м³',
      Type_45t: '45т',
      Type_60t: '60т',
      Type_100t: '100т',
      CourierDelivery: 'Курьерская доставка',
    }))(),
    CarTypeTonnage: ((): Record<CarType, string> => ({
      Type_1_5t: '1,5 т',
      Type_3t: '3 т',
      Type_5t: '5 т',
      Type_10t: '10 т',
      Type_20t: '20 т',
      Type_20tplus: '20+ т',
      Type_22t: '22 т',
      Type_30t: '30 т',
      Type_35t: '35 т',
      Type_45t: '45 т',
      Type_60t: '60 т',
      Type_100t: '100 т',
    }))(),
    AirTypeTonnage: ((): Record<AirType, string> => ({
      Type_3t: '3 т',
      Type_20t: '20 т',
      Type_UAV_1_1t: 'ВТ-440: 100 кг',
      Type_UAV_2_1t: 'SH-450: 100 кг',
      Type_UAV_2_3t: 'SH-750: 300 кг',
    }))(),
    CarTypeLong: ((): Record<CarType, string> => ({
      Type_1_5t: '1,5 тонны, 8 м³',
      Type_3t: '3 тонны, 14 м³',
      Type_5t: '5 тонн, 20 м³',
      Type_10t: '10 тонн, 32 м³',
      Type_20t: '20 тонн, 82 м³',
      Type_20tplus: '20 тонн, 113 м³',
      Type_22t: '22 тонны, 120 м³',
      Type_30t: '30 тонн, 120 м³',
      Type_35t: '35 тонн, 180 м³',
      Type_45t: '45 тонн',
      Type_60t: '60 тонн',
      Type_100t: '100 тонн',
    }))(),
    CarTypeDimensions: ((): Record<Exclude<CarType, 'CourierDelivery'>, string> => ({
      Type_1_5t: '3 x 1.8 x 1.5 (ДxШxВ)',
      Type_3t: '3.5 x 2 x 2 (ДxШxВ)',
      Type_5t: '4.5 x 2 x 2.2 (ДxШxВ)',
      Type_10t: '6 x 2.2 x 2.4 (ДxШxВ)',
      Type_20t: '13.6 x 2.4 x 2.5 (ДxШxВ)',
      Type_20tplus: '15 x 2.5 x 3 (ДxШxВ)',
      Type_22t: '16 x 2.5 x 3 (ДxШxВ)',
      Type_30t: '16 x 2.5 x 3 (ДxШxВ)',
      Type_35t: '15 x 3 x 4 (ДxШxВ)',
      Type_45t: 'Любые габариты',
      Type_60t: 'Любые габариты',
      Type_100t: 'Любые габариты',
    }))(),
    AirType: ((): Record<AirType, string> => ({
      Type_3t: '3 т/22м³',
      Type_20t: '20 т/124м³',
      Type_UAV_1_1t: 'Радар ММС, ВТ-440: 100 кг',
      Type_UAV_2_1t: 'Аэромакс, SH-450: 100 кг',
      Type_UAV_2_3t: 'Аэромакс, SH-750: 300 кг',
    }))(),
    AirTypeName: ((): Record<AirType, string> => ({
      Type_3t: 'МИ-8',
      Type_20t: 'МИ-26',
      Type_UAV_1_1t: 'БВС ВТ 440',
      Type_UAV_2_1t: 'БВС SH 450',
      Type_UAV_2_3t: 'БВС SH 750',
    }))(),
    AirTypeLong: ((): Record<AirType, string> => ({
      Type_3t: '3 тонны, 22 м³',
      Type_20t: '20 тонн, 124 м³',
      Type_UAV_1_1t: '100 кг',
      Type_UAV_2_1t: '100 кг',
      Type_UAV_2_3t: '300 кг',
    }))(),
    AirTypeDimensions: ((): Record<AirType, string> => ({
      Type_3t: '5,34 x 2,3 x 1.8 м (ДxШxВ)',
      Type_20t: '12 x 3,25 x 3,17 м (ДxШxВ)',
      Type_UAV_1_1t: '1.2 x 0.5 x 0.5 (ДxШxВ)',
      Type_UAV_2_1t: '1.2 x 0.5 x 0.5 (ДxШxВ)',
      Type_UAV_2_3t: '1.2 x 0.5 x 0.5 (ДxШxВ)',
    }))(),
    TrainType: ((): Record<TrainType, string> => ({
      Type_carriage_69t: '69 т',
    }))(),
    AirTypeAnyDimensions: 'Снаружи: любые габариты',
    ServiceType: ((): Record<ServiceType, string> => ({
      UrgentOneDay: 'Срочная подача (24ч)',
      UrgentThreeDays: 'Срочная подача (72ч)',
      Dangerous: 'Опасный груз',
      Insurance: 'Страховка',
      Refrigerator: 'Рефрижератор',
      SecondDriver: 'Второй водитель',
      Winter: 'Зима',
      ToStationDelivery: 'Доставка до станции отправления',
      FromStationDelivery: 'Доставка от станции назначения',
      ToAirportDelivery: 'Доставка до взлетной площадки',
      FromAirportDelivery: 'Доставка от взлетной площадки',
    }))(),
    ServiceDecription: ((): Record<ServiceType, string> => ({
      UrgentOneDay: 'Подача машины в течение 24 часов',
      UrgentThreeDays: 'Подача машины в течение 72 часов',
      Dangerous: 'Перевозим грузы 3, 4, 5, 8 и 9 классов опасности',
      Insurance: 'Страховка',
      Refrigerator: 'Если требуется соблюдение температурного режима',
      SecondDriver: 'Уменьшает время в пути вдвое',
      Winter: 'Зима',
      ToStationDelivery: 'Доставка до станции отправления',
      FromStationDelivery: 'Доставка от станции назначения',
      ToAirportDelivery: 'Доставка до взлетной площадки',
      FromAirportDelivery: 'Доставка от взлетной площадки',
    }))(),
    StatusOrderLabelDivisions: ((): Record<StatusOrder, string> => ({
      InProcessing: 'В обработке',
      Executed: 'Исполняется',
      Clarify: 'Уточнить',
      Calc: 'Рассчитать',
      Approve: 'Согласовать',
      CancelPerformer: 'Отменен И.',
      CancelCustomer: 'Отменен З.',
      FindExecutor: 'Поиск исполнителя',
    }))(),
    StatusOrderLabelAdmin: ((): Record<StatusOrder, string> => ({
      InProcessing: 'В обработке',
      Executed: 'Исполняется',
      Clarify: 'Уточняется',
      Calc: 'Рассчитывается',
      Approve: 'На согласовании',
      CancelPerformer: 'Отменен И.',
      CancelCustomer: 'Отменен З.',
      FindExecutor: 'Поиск исполнителя',
    }))(),
    StatusOrderLabelLogistician: ((): Record<StatusOrder, string> => ({
      InProcessing: 'Обработать',
      Executed: 'Исполняется',
      Clarify: 'На уточнении',
      Calc: 'Рассчитать',
      Approve: 'На согласовании',
      CancelPerformer: 'Отменен И.',
      CancelCustomer: 'Отменен З.',
      FindExecutor: 'Указать исполнителя',
    }))(),
  },
  BuyerType: ((): Record<Extract<UserRole['result'], 'Anonymous' | 'Guest' | 'Reserv' | 'Division'>, string> => ({
    Anonymous: 'CargoConnect',
    Guest: 'Гость',
    Reserv: 'Reserv',
    Division: 'Дивизион',
  }))(),
  Units: {
    Pieces: 'шт',
    Kg: 'кг',
    Meters: 'м',
    Centimeters: 'см',
    Meters3: 'м3',
    CubeSign: '3',
    Ruble: '₽',
    Kilometer: 'км',
  },
  Validation: {
    Messages: {
      Required: 'Обязательное поле',
      Email: 'Неверный формат почты',
      MaxWeight: 'Превышение максимального веса',
      Phone: 'Неверный формат номера телефона',
      InvalidFormat: 'Неверный формат',
    },
  },
};

export default ru;
