import 'shared/config/fonts';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'shared/theme/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './app';

const container = document.getElementById('root');

if (!container) {
  throw new Error('No root element.');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
