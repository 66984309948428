import {
  HttpError,
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IRetryPolicy,
  LogLevel,
} from '@microsoft/signalr';
import { getHost } from 'shared/lib/url';
import auth from 'shared/api/auth';
import { EventType } from './model';

export type Settings = {
  host: string;
  hub: string;
};

class Events {
  private _connection?: HubConnection;
  private readonly _settings: Settings = {
    host: getHost(),
    hub: 'notification',
  };

  constructor() {
    this.createConnection();
  }

  public async connect(): Promise<void> {
    try {
      if (this._connection && this._connection.state === HubConnectionState.Disconnected) {
        await this._connection.start().catch((error: HttpError | undefined) => {
          if (error && (error.statusCode === 403 || error.statusCode === 401)) {
            void auth.login();
          }
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Events error.`, error);
      throw error;
    }
  }

  public on(name: EventType, handler: (...args: unknown[]) => unknown): void {
    this.ensureConnectionCreated();
    this._connection?.on(name, handler);
  }

  public off(name: EventType, handler: (...args: unknown[]) => unknown): void {
    this.ensureConnectionCreated();
    this._connection?.off(name, handler);
  }

  public onReconnecting(handler: () => void): void {
    this.ensureConnectionCreated();
    this._connection?.onreconnecting(handler);
  }

  public onReconnected(handler: () => unknown): void {
    this.ensureConnectionCreated();
    this._connection?.onreconnected(handler);
  }

  public onLogisticiansNotify(handler: (...args: unknown[]) => void): void {
    this.on('LogisticiansNotify', handler);
  }

  public onUserNotify(handler: (...args: unknown[]) => void): void {
    this.on('UserNotify', handler);
  }

  private createConnection(): void {
    const { host, hub } = this._settings;

    // переподключаться бесконечно каждые 3 секунды
    const retryPolicy: IRetryPolicy = {
      nextRetryDelayInMilliseconds: () => 3000,
    };

    this._connection = new HubConnectionBuilder()
      .withUrl(`${host}/${hub}`, {
        transport: HttpTransportType.LongPolling,
        accessTokenFactory: () => auth.getToken(),
      })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  private ensureConnectionCreated(): void {
    if (!this._connection) {
      throw new Error('No connection in events manager.');
    }
  }
}

export default new Events();
